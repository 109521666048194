// IMPORTS
@import (reference) "settings.less";

// PROMO BAR
.promo-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: 192px;
    margin-bottom: calc(-1 * var(--rowGap));

    &:before {
        .full-bg();
    }

    &:has(.promo-bar-logo:only-child) {
        display: none;
    }

    &:has(.promo-banners-wrapper) {
        justify-content: space-between;

        .promo-social-links {
            position: static;
            transform: none;
        }
    }
}

.promo-social-links {
    display: flex;
    gap: 12px;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    i {
        font-style: normal;
        text-transform: uppercase;
        color: white;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.3;
        margin-right: 29px;
    }

    a:before {
        .small-icon();
        font-size: 32px;
        color: white;
    }

    a.facebook-link:before {
        content: "\e915";
    }

    a.instagram-link:before {
        content: "\e914";
    }
}

.promo-bar-logo {
    height: 100%;
    width: 323px;
    position: relative;
    margin-left: -33px;
    margin-right: 66px;
    z-index: -1;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 60%;
        background: @main-color;
        width: 100vw;
        height: 100%;
        z-index: -2;
    }

    .overflow-wrapper {
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;

        figure {
            position: absolute;
            top: calc(50% + 4px);
            transform: translateY(-50%);
            right: 0;
            width: 100%;
            aspect-ratio: ~"320/284";

            img {
                .img-crop();
            }
        }
    }
}

.promo-banners-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .promo-banner {
        .grid();
        grid-template-columns: 48px minmax(0, 1fr);
        align-items: center;
        gap: 0 20px;

        figure {
            width: 100%;
            aspect-ratio: 1;
            grid-row: 1 / 3;

            img {
                .img-contain();
            }
        }

        b, p {
            line-height: 1.3;
        }
    }
}

// NEWSLETTER
#newsletter {
    position: relative;
    color: @header-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-bottom: calc(-1 * var(--rowGap));
    padding: var(--rowGap) 0;
    overflow: hidden;

    &.unchecked .newsletter-clauses-wrapper {

        &:after {
            content: 'Zaznaczenie zgody jest konieczne, aby zapisać się do Newslettera';
            color: @error-color;
        }
    }
}

.newsletter-header {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 8px;

    b {
        font-size: 24px;
        line-height: 1.3;
        text-transform: uppercase;
    }
}

.newsletter-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 704px;
    position: relative;

    &:after {
        z-index: -1;
        position: absolute;
        top: -50%;
        left: calc(50% + 226px);
        transform:
            rotate(30deg)
            translateY(-50%);
        content: "\e913";
        .small-icon();
        font-size: 214px;
        color: @light-bg;
    }

    input[type="text"] {
        width: 100%;
        height: 48px;
        background-color: @total-color;
        border: 1px solid @border-color;
        border-radius: 30px;
        padding-right: 72px;
        color: @header-color;
        font-size: 16px;
        font-family: @main-font-family;

        &:focus::placeholder {
            color: transparent;
        }

        &::-webkit-input-placeholder {
            color: @header-color;
        }
        &::-moz-placeholder {
            color: @header-color;
        }
        &:-ms-input-placeholder {
            color: @header-color;
        }
        &:-moz-placeholder {
            color: @header-color;
        }
    }

    .popup-trigger {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 72px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
            .small-icon;
            content: "\e913";
        }

        &:hover {
            color: @main-color;
        }
    }
}

.newsletter-clauses-wrapper.unchecked {
    transform: translateY(-10px);

    &:after {
        content: 'Zaznaczenie zgody jest konieczne, aby zapisać się do Newslettera';
        clear: both;
        color: @error-color;
    }
}

// FOOTER
#footer {
    position: relative;
    color: @footer-gray;
    font-size: 14px;

    &:after {
        content: '';
        .center-lr();
        top: 0;
        width: 101vw;
        height: 100%;
        z-index: -2;
        background-color: #171717;
    }
}

.footer-content {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255,255,255,.31);
    padding: 72px 0;
    gap: 20px;
}

.footer-item {

    > strong {
        color: white;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 16px;
        display: block;
        line-height: 1.3;
    }
}

.footer-item.submenu {

    > strong {
        margin-bottom: 6px;
    }

    ul {
        columns: 3;
        column-gap: 90px;
    }

    a {
        display: inline-block;
        padding: 10px 0;
        color: @footer-gray;

        &:hover {
            color: white;
        }
    }
}

.footer-item.contact {
    max-width: 33%;

    a {
        color: @footer-gray;

        &:hover {
            color: white;
        }
    }

    .contact-wrapper {
        .grid();
        grid-template-columns: repeat(2, auto);
        gap: 24px 14px;
        justify-items: start;

        > * {
            position: relative;
            padding-left: 36px;

            &:before {
                .small-icon();
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    .contact-location {
        grid-column: 1 / 3;

        b {
            font-weight: normal;
            display: block;
        }

        span {
            display: inline-block;

            &:after {
                content: ',';
            }

            &:empty,
            &:last-child {

                &:after {
                    display: none;
                }
            }
        }

        &:before {
            content: "\e912";
        }
    }

    .contact-phones {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &:before {
            content: "\e911";
        }
    }

    .contact-email {

        &:before {
            content: "\e910";
        }
    }
}

.footer-logo {
    width: 144px;
    aspect-ratio: ~"144/128";
    margin-top: 18px;
    align-self: flex-start;

    img {
        .img-contain();
    }
}

.footer-bottom-bar {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;

    a {
        color: #C5C5C5;

        &:hover {
            color: white;
        }
    }

    > span {
        color: #C5C5C5;
    }
}